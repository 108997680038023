@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.btn {
    background-color: $main-color;
    border-radius: 16px;
    padding: 16px 32px;
    border: none;
    font-size: 18px;
    color: #FFFFFF;
    width: fit-content;
    cursor: pointer;

    @include respond-mobile {
        font-size: 14px;
    }
}

.fullwidth {
    width: 100%;
}

.premium {
    background: #FFBC39;
}