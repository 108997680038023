@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.header {
    position: sticky;
    top: 0;
    z-index: 100001;
    width: 100%;
    height: 94px;
    color: #FFFFFF;
    background-color: #000;

    @include respond-mobile {
        height: 80px;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 100001;
    }

    &__content {
        max-width: 1408px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-mobile {
            max-width: unset;
            margin: 0;
            padding: 0 32px;
        }

        &__controls {
            display: flex;
            align-items: center;

            @include respond-mobile {
                display: none;
            }

            &__links {
                display: flex;
                gap: 32px;
                margin-right: 32px;

                & a {
                    color: inherit;
                    text-decoration: none;
                    font-size: 24px;
                    line-height: 28px;
                }
            }
        }
    }

    &__menu {
        display: none;

        @include respond-mobile {
            display: block;
        }
    }
}