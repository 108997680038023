@import '../../styles/mixins.scss';

.menu {
    width: 100vw;
    // height: calc(100vh - 80px);
    position: absolute;
    display: none;
    transform: translateX(-100%);
    transition: transform .3s ease-in-out;
    top: 80px;
    left: 0;
    flex-direction: column;
    background: #000;
    backdrop-filter: blur(24px);
    padding: 64px 32px;
    box-sizing: border-box;

    &_open {
        transform: translateX(0%);
    }

    @include respond-mobile {
        display: flex;
    }

    &__links {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;

        a {
            font-weight: 1000;
            font-size: 20px;
            line-height: 120%;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #FFFFFF;
            text-decoration: none;
        }
    }

    &__brand {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        opacity: 0.5;
    }

    &__social {
        display: flex;
        gap: 40px;
        margin-top: 20px;
        align-items: center;

        svg {
            path {
                fill: #888888;
            }
        }
    }
}