@import '../../../styles/variables.scss';

.custom-select {

    &__control {
        background-color: $main-color  !important;
        border-radius: 0 !important;
        border: none !important;
        height: 48px;
        box-shadow: none !important;
    }

    &__single-value {
        font-weight: 900;
        font-size: 18px;
        line-height: 120%;
        color: #FFFFFF !important;
    }

    &__value-container {
        padding: 12px 20px !important;
    }

    &__indicator-separator {
        display: none;
    }

    &__indicators {
        padding-right: 20px;
    }

    &__menu {
        margin-top: 0 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
    }

    &__menu-list {
        padding: 0 !important;
    }

    &__option {
        padding: 12px 20px !important;
        font-weight: 600;
        font-size: 18px !important;
        line-height: 120%;
        color: #333333 !important;

        &--is-selected {
            background-color: #E0E0E0 !important;
        }
    }
}