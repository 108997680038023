@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.rates {
    padding-top: 96px;
    padding-bottom: 96px;
    background: #F2F2F2;

    @include respond-mobile {
        padding: 32px 32px 64px 32px;
    }

    &__content {
        max-width: 1408px;
        margin: 0 auto;

        @include respond-mobile {
            max-width: unset;
            margin: 0;
        }

        &__title {
            text-transform: uppercase;
            font-size: 56px;
            line-height: 120%;
            font-weight: 1000;
            letter-spacing: .03em;
            position: relative;
            line-height: 120%;
            color: #333333;
            display: inline-block;

            @include respond-mobile {
                font-size: 32px;
            }

            &::after {
                content: "";
                position: absolute;
                background: $main-color;
                left: 0;
                bottom: -24px;
                height: 2px;
                width: 50%;
            }
        }

        &__tabs {
            margin-top: 72px;

            @include respond-mobile {
                display: none;
            }

            &__list {
                display: flex;

                &__tab {
                    flex: 1;
                    padding: 32px;
                    text-align: center;
                    color: #828282;
                    font-size: 24px;
                    line-height: 32px;
                    border-radius: 0;
                    outline: none;
                    transition: all .3s linear;
                    border-left: 1px solid #E0E0E0;
                    cursor: pointer;

                    &_first {
                        border-left: none;
                    }

                    &_selected {
                        border-left: none;
                        background-color: $main-color;
                        color: #FFFFFF;
                    }
                }
            }
        }

        &_mobile {
            display: none;

            @include respond-mobile {
                display: block;
                margin-top: 56px;
            }
        }
    }
}