@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.workspace {
    margin-top: 48px;

    @include respond-mobile {
        margin-top: 24px;
    }
}

.open-space {
    padding: 32px;
    display: flex;
    align-items: center;
    gap: 32px;
    background: #FFFFFF;

    @include respond-mobile {
        flex-direction: column;
        background: none;
        padding: 0;
    }

    &__block {
        display: flex;
        flex-direction: column;

        &__title {
            color: $main-color;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 36px;
            line-height: 42px;
            letter-spacing: .05em;
            margin-bottom: 8px;

            @include respond-mobile {
                font-size: 24px;
                line-height: 32px;
            }
        }

        &__subtitle {
            color: #333;
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 24px;

            @include respond-mobile {
                font-size: 14px;
                margin-bottom: 16px;
            }
        }

        ul {
            li {
                margin-bottom: 20px;
                font-weight: 600;
                display: flex;
                gap: 8px;

                @include respond-mobile {
                    gap: 4px;
                }

                p {
                    flex: 1;
                    font-size: 16px;
                    line-height: 150%;

                    @include respond-mobile {
                        font-size: 12px;
                    }
                }

                svg {
                    @include respond-mobile {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

            .last {
                margin: 0;
            }
        }
    }

    &__slider {
        width: 688px;
        height: 520px;

        @include respond-mobile {
            width: 100%;
            height: 224px;
        }

        &__page {
            width: 100%;
            height: 100%;
            position: relative;

            &__gradient {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 32.29%, rgba(0, 0, 0, 0.8) 100%);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include respond-mobile {
                    height: 224px;
                }
            }
        }
    }

    &__tariffs {
        display: none;
        flex-direction: column;

        @include respond-mobile {
            display: flex;
            margin-top: 24px;
        }
    }
}

.first-slider {
    &-arrow {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #FFFFFF;
        transform: translateY(-50%);
        border-radius: 50%;
        z-index: 1000;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &-prev {
            left: 24px;
            top: 50%;

            @include respond-mobile {
                left: 8px;
            }
        }

        &-next {
            right: 24px;
            top: 50%;

            @include respond-mobile {
                right: 8px;
            }
        }
    }
}

.first-slider-dots {
    position: absolute;
    left: 50%;
    bottom: 16px;
    display: none !important;
    gap: 4px;
    transform: translateX(-50%);

    @include respond-mobile {
        display: flex !important;
    }

    li {
        button {
            width: 6px;
            height: 6px;
            background: #FFFFFF;
            border: none;
            color: transparent;
            opacity: 0.3;
            border-radius: 50%;
            padding: 0;
            transition: opacity .5s ease-in-out;
        }
    }

    .slick-active {
        button {
            opacity: 1;
        }
    }
}

.second-slider {
    margin-top: 48px;
    padding: 0 96px;

    @include respond-mobile {
        display: none;
    }

    &-arrow {
        position: absolute;
        width: 64px;
        height: 64px;
        transform: translateY(-50%);
        border-radius: 50%;
        z-index: 1000;
        border: 1px solid $main-color;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &-prev {
            left: -96px;
            top: 50%;
        }

        &-next {
            right: -96px;
            top: 50%;
        }
    }
}