@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.for-whom-card {
    padding: 32px;
    border: 2px solid $main-color;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    z-index: 1000;
    background: #FFFFFF;

    @include respond-mobile {
        padding: 16px;
    }

    &__img {
        width: 184px;
        height: 184px;
        margin-bottom: 32px;

        @include respond-mobile {
            width: 104px;
            height: 104px;
        }
    }

    &__caption {
        margin-bottom: 16px;
        font-weight: 900;
        font-size: 24px;
        line-height: 150%;
        text-transform: uppercase;

        @include respond-mobile {
            font-size: 18px;
        }
    }

    &__text {
        text-align: center;
        margin-bottom: 32px;
        font-size: 18px;
        line-height: 150%;
        flex: 1;

        @include respond-mobile {
            font-size: 14px;
        }
    }
}