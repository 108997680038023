@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.advantages {
    position: relative;
    background: #FFFFFF;
    z-index: 10;

    &__dots {
        width: 296px;
        height: 672px;
        background: url('../../assets/images/dots-3.svg');
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 100;

        @include respond-mobile {
            display: none;
        }
    }

    &__content {
        padding: 96px 0;
        margin: 0 auto;
        max-width: 1408px;

        @include respond-mobile {
            padding: 32px;
            margin: 0;
            max-width: unset;
        }

        &__title {
            text-transform: uppercase;
            font-size: 56px;
            font-weight: 1000;
            line-height: 120%;
            letter-spacing: 0.03em;
            color: #333333;
            position: relative;
            display: inline-block;

            @include respond-mobile {
                font-size: 28px;
            }

            &::after {
                content: "";
                position: absolute;
                height: 2px;
                width: 50%;
                background: $main-color;
                left: 0;
                bottom: -24px;
            }
        }

        &__list {
            margin-top: 80px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;

            @include respond-mobile {
                margin-top: 56px;
                flex-wrap: nowrap;
                flex-direction: column;
            }

            &__item {
                position: relative;
                padding-left: 16px;
                width: 432px;
                margin-bottom: 56px;

                @include respond-mobile {
                    margin-bottom: 32px;
                    width: auto;
                    padding-left: 8px;
                }

                &__title {
                    color: $main-color;
                    font-weight: 900;
                    font-size: 27px;
                    line-height: 150%;
                    text-transform: uppercase;

                    @include respond-mobile {
                       font-size: 20px;
                    }
                }

                &__text {
                    color: #333333;
                    font-size: 20px;
                    line-height: 150%;
                    font-size: 300;

                    @include respond-mobile {
                        font-size: 16px;
                     }
                }

                &::before {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 100%;
                    background: $main-color;
                    left: 0;
                }

                &_last {
                    @include respond-mobile {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}