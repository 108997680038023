@import '../../../../styles/mixins.scss';

.card {
    padding: 32px;
    background: #FFFFFF;
    max-width: 448px;
    margin-right: 32px;
    display: flex;
    flex-direction: column;
    min-height: 798px;

    @include respond-mobile {
        max-width: unset;
        margin: 0;
        min-height: unset;
        padding: 16px 20px;
    }

    &_no_margin {
        margin: 0;
    }

    &_premium {
        border: 1px solid #FFBC39;
    }

    &_last_three {
        min-height: 1014px;
    }

    &_last {
        margin-right: 2px;

        @include respond-mobile {
            margin: 0;
        }
    }

    &__title {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 32px;
        font-weight: 600;
        color: #333333;

        @include respond-mobile {
            display: none;
        }
    }

    &__divider {
        width: 100%;
        height: 1px;
        background: #E5E5E5;

        &_mobile {
            @include respond-mobile {
                display: none;
            }
        }
    }

    &__services {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 32px;

        @include respond-mobile {
            margin: 0;
            margin-bottom: 12px;
        }

        li {
            display: flex;
            gap: 12px;
            font-size: 16px;
            line-height: 24px;

            @include respond-mobile {
                font-size: 10px;
                line-height: 16px;
                gap: 8px;
            }

            span {
                flex: 1;
            }

            svg {
                @include respond-mobile {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &__price {
        color: #333333;
        font-weight: 900;
        font-size: 48px;
        line-height: 52px;
        margin: 32px 0;

        @include respond-mobile {
            margin: 12px 0;
            font-size: 32px;
            line-height: 34px;
        }
    }
}