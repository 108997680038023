@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.other-services {
    margin-top: 48px;
    background: #FFFFFF;
    padding: 32px;
    display: flex;

    @include respond-mobile {
        flex-direction: column;
        background: none;
        padding: 0;
        margin-top: 24px;
        gap: 24px;
    }

    &__section {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;

        @include respond-mobile {
            gap: 8px;
        }

        &__caption {
            display: flex;
            flex-direction: column;

            @include respond-mobile {
                flex-direction: row;
                gap: 8px;
            }

            svg {
                @include respond-mobile {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        &_last {
            padding-left: 32px;
            border-left: 1px solid #BDBDBD;

            @include respond-mobile {
                padding-left: 0;
                border-left: none;
                border-top: 1px solid #BDBDBD;
                padding-top: 24px;
            }
        }

        &__title {
            font-weight: 900;
            font-size: 36px;
            line-height: 160%;
            color: $main-color;
            text-transform: uppercase;

            @include respond-mobile {
                font-size: 20px;
            }
        }

        &__price {
            font-weight: 900;
            font-size: 32px;
            line-height: 133%;
            color: #333333;

            @include respond-mobile {
                font-size: 24px;
            }

            &_last {
                padding-left: 16px;
                border-left: 1px solid #BDBDBD;

                @include respond-mobile {
                    padding: 0;
                    border-left: none;
                }
            }

            @include respond-mobile {
                font-size: 20px;
            }
        }

        &__price_block {
            display: flex;
            gap: 32px;

            @include respond-mobile {
                flex-direction: column;
                gap: 4px;
            }
        }
    }
}