@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.meeting-rooms {
    margin-top: 48px;
    padding: 32px;
    display: flex;
    gap: 32px;
    background: #FFFFFF;

    @include respond-mobile {
        margin-top: 0;
        flex-direction: column;
        padding: 0;
        background: none;
        gap: unset;
    }

    &__block {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__image {
            display: none;
            width: 100%;
            height: 178px;
            object-fit: cover;
            margin-bottom: 8px;

            @include respond-mobile {
                display: block;
            }
        }

        &__title {
            color: #333;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 36px;
            line-height: 42px;
            letter-spacing: .05em;
            margin-bottom: 8px;

            @include respond-mobile {
                display: none;
            }

            span {
                color: $main-color;
            }
        }

        &__label {
            font-weight: 700;
            font-size: 20px;
            line-height: 150%;
            color: $main-color;
        }

        &__value {
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 8px;

            b {
                font-weight: bold;
            }

            sup {
                vertical-align: super;
                font-size: smaller;
            }

            @include respond-mobile {
                margin-bottom: 12px;
                font-size: 16px;
                font-weight: 300;
            }
        }

        &__additionally {
            margin-top: -8px;
            font-size: 18px;
            line-height: 27px;
            color: #333;

            b {
                font-weight: bold;
            }

            @include respond-mobile {
                margin-bottom: 12px;
                font-size: 16px;
                font-weight: 300;
            }
        }

        &__price {
            font-weight: 900;
            font-size: 32px;
            line-height: 40px;
            color: #333;
            flex-grow: 1;
            margin-top: 24px;

            @include respond-mobile {
                margin-top: 4px;
                margin-bottom: 16px;
            }

            &__label {
                font-weight: 300;
                font-size: 20px;
                line-height: 150%;

                @include respond-mobile {
                    margin-top: -16px;
                    margin-bottom: 16px;
                }

                b {
                    font-weight: bold;
                }

                &_flex {
                    flex-grow: 1;
                }
            }

            &_no_flex {
                flex-grow: 0;
            }
        }
    }

    &__slider {
        width: 864px;
        height: 520px;

        @include respond-mobile {
            display: none;
        }

        &__page {
            width: 100%;
            height: 100%;
            position: relative;

            img {
                width: 100%;
                max-height: 520px;
                object-fit: cover;
            }

            &__gradient {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 32.29%, rgba(0, 0, 0, 0.8) 100%);
            }
        }
    }

    &_mobile {
        display: none;
        flex-direction: column;

        p {
            margin: 24px 0;
            font-weight: 300;
            font-size: 14px;
            line-height: 150%;
            color: #333333;
        }

        @include respond-mobile {
            display: flex;
        }
    }
}

.slider {
    &-arrow {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #FFFFFF;
        transform: translateY(-50%);
        border-radius: 50%;
        z-index: 1000;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &-prev {
            left: 24px;
            top: 50%;
        }

        &-next {
            right: 24px;
            top: 50%;
        }
    }
}

.meeting-rooms-slider-dots {
    position: absolute;
    left: 50%;
    bottom: 24px;
    display: flex !important;
    gap: 8px;
    transform: translateX(-50%);

    li {
        button {
            width: 8px;
            height: 8px;
            background: #FFFFFF;
            border: none;
            color: transparent;
            opacity: 0.3;
            border-radius: 50%;
            padding: 0;
            transition: opacity .5s ease-in-out;
        }
    }

    .slick-active {
        button {
            opacity: 1;
        }
    }
}