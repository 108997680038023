@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.contact-form {
    height: 100vh;
    display: flex;
    position: relative;

    @include respond-mobile {
        height: auto;
        display: block;
    }

    &__form-background {
        width: 50%;
        height: 100%;
        background-color: $main-color;

        @include respond-mobile {
            display: none;
        }
    }

    &__invisible-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        @include respond-mobile {
            position: relative;
            top: unset;
            left: unset;
        }
    }

    &__form-wrapper {
        z-index: 100;
        height: 100%;
        max-width: 1408px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        @include respond-mobile {
            max-width: unset;
            margin: 0;
            height: auto;
            background-color: $main-color;
        }

        &__form {
            max-width: 568px;
            padding: 48px;
            background: #FFFFFF;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            @include respond-mobile {
                max-width: unset;
                margin: 32px;
                padding: 24px;
            }

            &__title {
                color: $main-color;
                font-size: 40px;
                line-height: 120%;
                font-weight: 900;
                text-transform: uppercase;
                margin-bottom: 16px;

                @include respond-mobile {
                    font-size: 22px;
                    margin-bottom: 8px;
                }
            }

            &__subtitle {
                color: #A0A0A0;
                font-size: 20px;
                line-height: 150%;
                margin-bottom: 32px;

                @include respond-mobile {
                    font-size: 14px;
                    color: #333333;
                    margin-bottom: 16px;
                }

                b {
                    font-weight: bold;
                }
            }

            &__label {
                display: flex;
                flex-direction: column;
                color: $main-color;
                font-size: 14px;
                line-height: 20px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            &__input {
                margin-top: 8px;
                padding: 16px;
                margin-bottom: 24px;
                border: 1px solid #E0E0E0;
                padding: 16px 24px;
                border-radius: 8px;
                font-size: 20px;
                line-height: 150%;

                &::placeholder {
                    color: #E0E0E0;
                }
            }

            &__agreement {
                font-size: 16px;
                line-height: 120%;
                color: #828282;
                margin-top: 40px;

                @include respond-mobile {
                    font-size: 12px;
                    margin-top: 16px;
                    text-align: justify;
                }
            }
        }
    }

    &__slider {
        width: 50%;

        @include respond-mobile {
            display: none;
        }

        &__page {
            position: relative;

            p {
                position: absolute;
                left: 56px;
                bottom: 88px;
                font-size: 16px;
                line-height: 20px;
                color: #FFFFFF;
                max-width: 555px;
            }

            img {
                width: 50vw;
                height: 100vh;
                object-fit: cover;
            }

            &__gradient {
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 61.46%, #000000 100%);
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }
}

.custom-dots {
    position: absolute;
    left: 56px;
    bottom: 56px;
    display: flex !important;
    gap: 8px;

    li {
        button {
            width: 8px;
            height: 8px;
            background: #FFFFFF;
            border: none;
            color: transparent;
            opacity: 0.3;
            border-radius: 50%;
            padding: 0;
            transition: opacity .5s ease-in-out;
        }
    }

    .slick-active {
        button {
            opacity: 1;
        }
    }
}