@import '../../styles/mixins.scss';

.first-img {
    background: url('../../assets/images/image-1.png');
}

.second-img {
    background: url('../../assets/images/image-2.png');
}

.third-img {
    background: url('../../assets/images/image-3.png');
}

.first-section__wrapper {
    background: #000000;
    position: relative;
    height: 100vh;
}

.first-section {
    height: 100%;
    background-size: cover;
    background-position: center center;

    display: flex;
    align-items: center;

    &__content {
        max-width: 1408px;
        margin: 0 auto;
        flex: 1;
        color: #FDFDFD;

        @include respond-mobile {
            max-width: unset;
            margin: inherit;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 32px;
        }

        @media (max-width:320px) {
            padding: 0;
        }

        &__label {
            font-weight: 800;
            font-size: 24px;
            line-height: 150%;
            margin-bottom: 24px;
            letter-spacing: 0.2em;
            position: relative;
            padding-left: 70px;

            @include respond-mobile {
                padding-left: 0px;
                font-size: 12px;
                text-transform: uppercase;
                margin-bottom: 32px;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                width: 50px;
                height: 2px;
                background-color: #FFBC39;
                transform: translateY(-50%);

                @include respond-mobile {
                    width: 32px;
                    left: 50%;
                    bottom: -8px;
                    top: unset;
                    transform: translateX(-50%);
                }
            }
        }

        &__title {
            font-weight: 700;
            font-size: 104px;
            width: 808px;
            margin-bottom: 8px;
            font-family: 'Gotham', sans-serif;

            @include respond-mobile {
                width: auto;
                font-size: 40px;
                text-align: center;
            }
        }

        &__brand {
            margin-bottom: 32px;
            font-size: 32px;
            line-height: 150%;

            @include respond-mobile {
                font-size: 16px;
                margin-bottom: 64px;
                text-align: center;
            }
        }
    }
}