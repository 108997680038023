@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.for-whom {
    background: #FFFFFF;
    position: relative;
    padding: 96px 0 288px 0;

    @include respond-mobile {
        padding: 32px 32px 160px 32px;
    }

    &__dots {
        width: 296px;
        height: 672px;
        background: url('../../assets/images/dots-1.svg');
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;

        @include respond-mobile {
            right: 0;
            bottom: 64px;
            top: unset;
            transform: unset;
        }
    }

    &__content {
        max-width: 1408px;
        margin: 0 auto;
        position: relative;

        @include respond-mobile {
            max-width: unset;
            margin: 0;
        }

        &__title {
            color: #000000;
            font-size: 56px;
            line-height: 120%;
            font-weight: 1000;
            letter-spacing: .03em;
            position: relative;
            display: inline;
            line-height: 120%;

            @include respond-mobile {
                font-size: 32px;
            }

            &::after {
                position: absolute;
                bottom: -24px;
                left: 0;
                content: "";
                background: $main-color;
                height: 2px;
                width: 50%;
            }
        }

        &__cards {
            margin-top: 120px;
            display: flex;
            justify-content: space-between;
            gap: 32px;
            z-index: 1000;

            @include respond-mobile {
                flex-direction: column;
                margin-top: 56px;
            }
        }
    }
}