@import '../../styles/mixins.scss';

.ecosystem {
    background: #000000;
    position: relative;
    z-index: 0;

    @include respond-mobile {
        overflow-x: hidden;
        min-height: unset;
    }

    &__dots {
        position: absolute;
        z-index: 100;
        background: url('../../assets/images/dots-2.svg');
        left: 50px;
        top: 50%;
        width: 406px;
        height: 672px;
        transform: translateY(-50%);
    }

    &__shape {
        position: absolute;
        z-index: 10;
        left: -4%;
        bottom: -10%;
        width: 651px;
        height: 427px;
        transform: rotate(-48deg);
        filter: blur(450px);
        background-color: #E48832;

        @include respond-mobile {
            top: -30%;
            left: 10%;
            width: 320px;
            height: 490px;
            transform: rotate(48deg);
            filter: blur(450px);
        }
    }

    &__content {
        z-index: 1000;
        max-width: 1408px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 32px;
        padding: 224px 0;

        @include respond-mobile {
            gap: 48px;
        }

        @include respond-mobile {
            flex-direction: column;
            padding: 64px 32px 96px 32px;
            max-width: unset;
            margin: 0;
        }

        &__text {
            &__title {
                font-size: 56px;
                font-weight: 1000;
                letter-spacing: 0.03em;
                line-height: 120%;
                width: 500px;
                color: #FFBC39;
                margin-bottom: 56px;
                position: relative;
                display: inline-block;

                @include respond-mobile {
                    width: auto;
                    font-size: 24px;
                    line-height: 120%;
                    margin-bottom: 24px;
                }


                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -24px;
                    width: 50%;
                    height: 2px;
                    background-color: #FFBC39;

                    @include respond-mobile {
                        bottom: -8px;
                    }
                }
            }

            &__subtitle {
                color: #FFFFFF;
                font-size: 24px;
                line-height: 150%;
                width: 691px;

                @include respond-mobile {
                    width: auto;
                    font-size: 18px;
                }

                strong {
                    font-weight: bold;
                }
            }
        }

        &__step_block {
            display: flex;
            flex-direction: column;
            gap: 48px;

            @include respond-mobile {
                gap: 24px;
            }

            &__item {
                display: flex;
                align-items: center;
                gap: 32px;

                @include respond-mobile {
                    gap: 16px;
                }

                &__icon {
                    padding: 12px;
                    border: 1px solid #FFBC39;
                    position: relative;
                    display: inline-block;
                    background: #000000;
                    z-index: 10000;

                    @include respond-mobile {
                        padding: 12px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: -48px;
                        width: 1px;
                        height: 48px;
                        background-color: #FFBC39;

                        @include respond-mobile {
                            bottom: -50%;
                            height: 50%;
                        }
                    }

                    &_last {
                        &::after {
                            content: none;
                        }
                    }
                }

                &__text {
                    color: #FFFFFF;
                    font-size: 24px;
                    line-height: 150%;
                    font-weight: 700;
                    text-transform: uppercase;

                    @include respond-mobile {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}