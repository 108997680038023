@import './reset.scss';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900;1000&display=swap');
@import url('http://fonts.cdnfonts.com/css/gotham?styles=17588');

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Inter', sans-serif;
}