@import '../../styles/mixins.scss';

.footer {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #111111;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-mobile {
        padding: 32px;
    }

    &__brand {
        color: #FFFFFF;
        opacity: .5;
        text-align: center;
        font-size: 18px;
        line-height: 150%;
        margin-top: 24px;
    }

    &__links {
        display: flex;
        gap: 32px;
        margin-top: 40px;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        align-items: center;

        @include respond-mobile {
            flex-direction: column;
            align-items: flex-start;
            align-self: flex-start;
            gap: 12px;
            font-size: 16px;
        }

        & a {
            color: inherit;
            text-decoration: none;
        }
    }

    &__social {
        display: flex;
        gap: 40px;
        margin-top: 40px;
        align-items: center;

        svg {
            path {
                fill: #888888;
            }
        }
    }
}