@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.offices {
    margin-top: 48px;
    padding: 32px;
    display: flex;
    align-items: center;
    gap: 32px;
    background: #FFFFFF;

    @include respond-mobile {
        flex-direction: column-reverse;
        padding: 0;
        background: none;
        margin-top: 24px;
        gap: 24px;
    }

    &__block {
        display: lex;
        flex-direction: column;

        &__title {
            color: #333;
            font-weight: 900;
            text-transform: uppercase;
            font-size: 36px;
            line-height: 133%;
            letter-spacing: .05em;
            margin-bottom: 8px;

            @include respond-mobile {
                font-size: 24px;
                color: $main-color;
            }
        }

        &__subtitle {
            color: #333;
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 24px;

            @include respond-mobile {
                margin-bottom: 16px;
                font-size: 14px;
            }
        }

        ul {
            li {
                margin-bottom: 20px;
                font-weight: 600;
                display: flex;
                align-items: center;
                gap: 8px;
                color: #333;
                font-size: 16px;
                line-height: 150%;

                @include respond-mobile {
                    font-size: 12px;
                    margin-bottom: 12px;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            p {
                flex: 1;
            }
        }

        &__btn {
            display: block;
            margin-top: 24px;

            @include respond-mobile {
                display: none;
            }

            &_mobile {
                display: none;
                order: -1;

                @include respond-mobile {
                    display: block;
                }
            }
        }
    }

    &__slider {
        width: 688px;
        height: 520px;

        @include respond-mobile {
            width: 100%;
            height: 224px;
        }

        &__page {
            width: 100%;
            height: 100%;
            position: relative;

            &__gradient {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 32.29%, rgba(0, 0, 0, 0.8) 100%);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include respond-mobile {
                    height: 224px;
                }
            }
        }
    }
}

.slider {
    &-arrow {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #FFFFFF;
        transform: translateY(-50%);
        border-radius: 50%;
        z-index: 1000;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &-prev {
            left: 24px;
            top: 50%;

            @include respond-mobile {
                left: 8px;
            }
        }

        &-next {
            right: 24px;
            top: 50%;

            @include respond-mobile {
                right: 8px;
            }
        }
    }
}

.offices-slider-dots {
    position: absolute;
    left: 50%;
    bottom: 24px;
    display: flex !important;
    gap: 8px;
    transform: translateX(-50%);

    @include respond-mobile {
        bottom: 16px;
        gap: 4px;
    }

    li {
        button {
            width: 8px;
            height: 8px;
            background: #FFFFFF;
            border: none;
            color: transparent;
            opacity: 0.3;
            border-radius: 50%;
            padding: 0;
            transition: opacity .5s ease-in-out;

            @include respond-mobile {
                width: 6px;
                height: 6px;
            }
        }
    }

    .slick-active {
        button {
            opacity: 1;
        }
    }
}