@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.contacts {
    height: 100vh;
    position: relative;

    @include respond-mobile {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        height: auto;
    }

    &__map {
        position: absolute;
        top: 0;
        left: 0;
        background: url('../../assets/images/map.png');
        width: 1146px;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;

        @include respond-mobile {
            position: static;
            top: unset;
            left: unset;
            width: 100vw;
            height: 100vh;
            background-position: -150px;
        }
    }

    &__dots {
        width: 344px;
        height: 570px;
        background: url('../../assets/images/dots-4.svg');
        position: absolute;
        right: 110px;
        top: 130px;

        @include respond-mobile {
            display: none;
        }
    }

    &__content {
        max-width: 1408px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include respond-mobile {
            max-width: unset;
            height: auto;
            margin: 0;
            display: block;
            justify-content: unset;
            align-items: unset;
        }

        &__block {
            width: 568px;
            padding: 64px;
            z-index: 1000;
            border: 1px solid #E0E0E0;
            background: #FFFFFF;
            box-sizing: border-box;

            @include respond-mobile {
                padding: 32px;
                width: auto;
            }

            &__title {
                font-weight: 900;
                font-size: 40px;
                line-height: 120%;
                color: $main-color;
                margin-bottom: 32px;
                text-transform: uppercase;

                @include respond-mobile {
                    margin-bottom: 16px;
                    font-size: 20px;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                gap: 24px;

                &__row {
                    display: flex;
                    gap: 8px;

                    &__text {
                        color: #333333;
                        font-size: 18px;
                        line-height: 24px;

                        @include respond-mobile {
                            font-size: 16px;
                        }

                        span {
                            color: #A0A0A0;
                        }

                        &__label {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 24px;
                            color: $main-color;
                            margin-bottom: 4px;
                        }
                    }
                }
            }
        }
    }
}